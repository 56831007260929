.footer {
  .docList {
    .docItem {
      font-size: 12px;
      margin-bottom: 10px;
    }

    .title {
      font-weight: bold;
    }
  }
  .footerCopyright {
    text-align: center;
  }
}
