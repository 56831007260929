.panel {
  box-shadow: 1px 1px 4px 1px #b7c5d2;
  border-radius: 4px;

  & + & {
    margin-top: 20px;
  }
}
.panelTitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #000;
}
.panelDesc {
  font-size: 14px;
  line-height: 22px;
  margin-top: 8px;
  color: #0f294c;
}
.panelHeader {
  // display: block;
  border-bottom: 1px solid #c2cbd6;
}
.panelBody,
.panelHeader {
  padding: 20px;
}

.themeGrey {
  background-color: var(--rv-gray-2);
}
.themeTransparent {
  background-color: transparent;
}

.sizeSmall {
  .panelBody,
  .panelHeader {
    padding: 10px;
  }
}
