.hotelImgWrapper {
  --switchIconSize: 42px;
  position: relative;
  margin-bottom: -36px;
  width: 100%;
  padding-bottom: 80%;
  .hotelImg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    // width: 100%;
    // height: 500px;
  }

  .imgCountWrapper {
    position: absolute;
    bottom: 44px;
    right: 20px;
    z-index: 1;
    color: white;
    padding: 6px 14px;
    border-radius: 4px;
    background-color: rgba(34, 34, 34, 0.55);

    .icon {
      vertical-align: middle;
      margin-top: -3px;
    }
  }

  .switchIcon {
    background-color: #fff;
    width: var(--switchIconSize);
    height: var(--switchIconSize);
    border-radius: var(--switchIconSize);
    line-height: var(--switchIconSize);
    color: #646464;
    position: absolute;
    top: 160px;
    z-index: 2;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.left {
      left: 22px;
    }
    &.right {
      right: 22px;
    }
  }
}

.nav {
  line-height: 22px;
  padding: 12px 40px;
  position: relative;
  text-align: center;
  .navIcon {
    position: absolute;
    left: 0;
    top: 0;
    padding: 12px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
  }
}

.navbackBtn {
  position: fixed;
  width: 100%;
  bottom: 20px;
  z-index: 1;
}

.imgGrid {
  .imgItem {
    margin-bottom: 10px;
    .img {
      height: 200px;
    }
  }
}
