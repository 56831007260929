.suggestionDropdown {
  width: 500px;
  :global {
    .ant-popover-arrow {
      display: none;
    }
    .ant-select-item-empty {
      color: var(--mu-text-color);
    }
    .ant-popover-inner-content {
      padding: 4px 0;
    }
    .ant-select-item {
      padding: 5px 12px;
    }
  }

  .defaultContent {
    padding: 5px 12px;
  }

  .suggestionItem {
    cursor: pointer;
    display: flex;
    gap: 12px;
    align-items: center;
    // padding: 5px 12px;
    &:hover {
      background-color: var(--mu-item-hover-bg);
    }
    .prefixIcon {
      font-size: 20px;
    }
  }
}

.destSuggestion {
  vertical-align: bottom;
  :global {
    .ant-select:not(.ant-select-customize-input) {
      width: 100%;
      .ant-select-selector {
        padding: 0;
        line-height: 16px;
        height: 16px;
        border: none;
        .ant-select-selection-search {
          left: 0;
          .ant-select-selection-search-input {
            height: 16px;
          }
        }
        .ant-select-selection-placeholder {
          height: 16px;
          line-height: 16px;
        }
        .ant-select-selection-item {
          color: #0e141f;
          line-height: 16px;
        }
      }
    }
    .ant-input-affix-wrapper {
      padding: 0;
      line-height: 16px;
    }
  }
}
