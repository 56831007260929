.wrapper {
  // position: relative;
}

.body {
  // height: 100%;
  // overflow-y: scroll;
  // margin-bottom: 50px;
}

.footer {
  text-align: center;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
}
