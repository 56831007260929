.occupancySetting {
  // background-color: var(--mu-background-color);
  width: 300px;
  .innnerWrapper {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.countContent {
  width: 30px;
  text-align: center;
  // line-height: 30px;
}

.dropdown {
  :global {
    .ant-popover-arrow {
      display: none;
    }
  }
}
