.wrapper {
  // display: flex;
  // align-items: center;
  // min-height: 768px;
  // background-color: #ffe6e2;
  .body {
    display: flex;
    overflow: hidden;
    border-radius: 10px;

    .content {
      flex: 1;
      padding: 10px 20px;
      width: 320px;
      background-color: #fff;
      .logo {
        width: 120px;
        height: 20px;
        margin-bottom: 40px;
      }
      .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 26px;
        font-family: 'App Custom Title';
      }

      :global {
        .mu-search-button {
          border-radius: 10px;

          box-shadow: 3px 3px 8px 0px #faafbd;
        }
      }
    }
    .imgWrapper {
      width: 385px;
      height: 460px;
      background-color: var(--mu-primary-color);
      img {
        width: 100%;
      }
    }
  }
}
