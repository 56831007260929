.hotel-rateplan-filter {
  line-height: 30px;

  &--round {
    --mu-border-radius: 15px;
  }

  &__heading {
    padding-left: 8px;
  }

  &__scroll-container {
    overflow-x: auto;
    flex: 1;
    min-width: 0;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    height: 30px;
    border-radius: var(--mu-border-radius, 4px);
    border: 1px solid #d5d9e2;
    line-height: 30px;
    padding: 0 12px;
    background: white;

    &--active {
      color: #0068ef;
      background: #f4f6f8;
      border: 1px solid currentColor;
    }
  }

  &__section {
    margin-bottom: 12px;
  }

  &__title {
    margin-bottom: 8px;
  }
}
