.layout {
  background-color: var(--mu-background-color);
  .layoutContainer {
    position: relative;
    max-width: 768px;
    margin: 0 auto;
  }
  &.theme_white {
    background-color: white;
  }
  .layoutBody {
    min-height: 100vh;
  }

  .layoutFooter {
    background-image: url(apps/hotelwhales/src/assets/images/bg-footer.webp);
    background-repeat: no-repeat;
    background-size: cover;
    // background-color: #15161b;
    padding: 20px 0;
    overflow: hidden;
    --rv-typography-color: #71717a;
    * {
      color: #71717a;
    }
  }
}
