.occupancy-setting {
  // background-color: var(--mu-background-color);
  margin-bottom: 8px;
}

.count-content {
  width: 30px;
  text-align: center;
  line-height: 30px;
}
