.mu-popup-dialog {
  &__header {
    --rv-cell-vertical-padding: 20px;
    border-bottom: 1px solid var(--mu-border-color);
    box-shadow: 0 -1px 2px 0 rgba(101, 117, 161, 0.13);
  }

  &__body {
    overflow-y: scroll;
  }

  &__footer {
    border-top: 1px solid var(--mu-border-color);
    box-shadow: 0 -1px 2px 0 rgba(101, 117, 161, 0.13);
  }
}
