.searchPanel {
  // height: 80px;
  line-height: 16px;

  .searchOption {
    padding: 6px 12px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #d5d9de;
    .icon {
      color: #005def;
      font-size: 20px;
    }
    .label {
      color: #48698b;
      font-weight: bold;
      text-align: left;
      white-space: nowrap;
      margin-bottom: 2px;
      line-height: 16px;
    }
    .value {
      color: #0e141f;
      text-align: left;
      white-space: nowrap;
      // line-height: 24px;
      cursor: pointer;
    }

    :global(input) {
      color: #0e141f;
      height: 16px;
    }
    .datePicker {
      padding: 0;
      border: 0;
      :global {
        .ant-picker-input {
          width: 83px;
        }
        .ant-picker-active-bar {
          margin-left: 0;
        }
      }
    }
  }

  .datePickerSeperater {
    font-size: 11px;
    line-height: 1;
    padding: 2px 6px;
    margin-bottom: -2px;
    border-radius: 8px;
    border: 1px solid currentColor;
    color: #3c6a8e;
  }

  .searchBtn {
    // padding: 16px;
    // height: auto;
    width: 100px;
    height: 100%;
  }
}

.datePickerDropdown {
  padding-top: 10px;
  :global {
    .ant-picker-range-arrow {
      display: none;
    }
  }
}
