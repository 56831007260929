.table {
  width: 100%;
  // font-size: 12px;
  background-color: #fff;
  border-radius: 2px;
  line-height: 24px;
  border: 1px solid #e7e8ec;
  // box-shadow: 0px 2px 4px 0px rgba(#989eaf, 0.13);
  border-collapse: collapse;
  .tableRow {
    .tableCell {
      padding: 3px 12px;
      border: 1px solid #e7e8ec;
    }
    &.success {
      background-color: var(--mu-success-color-bg);
      .tableCell {
        color: var(--mu-success-color) !important;
      }
    }
    &.warning {
      background-color: #fff8ee;
      .tableCell {
        color: #ff9800 !important;
      }
    }
    &.danger {
      background-color: #fff1f6;
      .tableCell {
        color: #ff0264 !important;
      }
    }
  }
  .tableHeader {
    color: #5a5a5a;
    font-weight: bold;
  }
}
