.nationality {
  padding-bottom: 10px;
  .input {
    padding: 10px;
    // margin-bottom: 10px;
  }
  .empty {
    padding: 20px;
  }
  .listWrapper {
    max-height: 400px;
    width: 300px;
    overflow-y: scroll;
    .item {
      cursor: pointer;
      line-height: 30px;
      padding: 0 10px;
      &:hover,
      &.active {
        background-color: var(--mu-item-hover-bg);
      }
      .code {
        display: inline-block;
        width: 2em;
      }
      .name {
        max-width: 200px;
      }
    }
  }
}

.dropdown {
  :global {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}
