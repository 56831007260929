:root {
  --mu-panel-border-radius: 8px;
  --mu-panel-bg: #f3f6f8;
  --mu-panel-inset-padding: 0 12px;
  --mu-panel-padding-v: 12px;
  --mu-panel-padding-h: 16px;
  --mu-panel-inset-shadow: 2px 2px 9px 0px #dadfe6;
}

.mu-panel {
  padding: var(--mu-panel-padding-v) var(--mu-panel-padding-h);
  background-color: #fff;
  // border-radius: var(--mu-panel-border-radius);

  &__body {
    --rv-cell-horizontal-padding: 0;
    .rv-cell::after {
      left: 0;
      right: 0;
    }
  }

  &--inset {
    border-radius: var(--mu-panel-border-radius);
    margin: var(--mu-panel-inset-padding);
    box-shadow: var(--mu-panel-inset-shadow);
    // .panelBody {
    //   --rv-cell-horizontal-padding: var(--mu-panel-padding-h);
    // }
  }
  &__header {
    margin: 8px 0 20px !important;
  }
  &__title {
    line-height: 18px;
    margin-bottom: 0;
  }
  &__desc {
    // color: #0f294c;
    margin-top: 4px;
  }
  &__eader {
    // display: block;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  &--grey {
    background-color: var(--mu-panel-bg);
    --rv-divider-border-color: var(--rv-gray-8);
    --rv-steps-background-color: transparent;
  }

  &--shadowless {
    box-shadow: none !important;
  }

  &--transparent {
    background-color: transparent;
  }
}
