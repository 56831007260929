.mu-table {
  width: 100%;
  font-size: 14px;
  color: #000;
  background-color: #fff;
  line-height: 24px;
  // border-radius: 4px;
  border-collapse: collapse;
  --table-cell-padding: 4px 12px;
  &--large {
    --table-cell-padding: 8px 20px;
  }

  &--bordered {
    tr + tr {
      border-top: 1px solid var(--mu-border-color);
    }
  }

  &__wrapper {
    // border-radius 在table上不生效
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--mu-border-color);

    &--inset {
      border-radius: 0;
    }
  }

  tbody,
  thead {
    tr {
      td,
      th {
        padding: var(--table-cell-padding);
        // border: 1px solid #e7e8ec;
      }
      &.success {
        background-color: var(--mu-success-color-bg);
        td {
          color: var(--mu-success-color) !important;
        }
      }
      &.warning {
        background-color: #fff8ee;
        td {
          color: #ff9800 !important;
        }
      }
      &.danger {
        background-color: #fff1f6;
        td {
          color: #ff0264 !important;
        }
      }
    }
    th {
      background-color: var(--mu-thead-bg-color, #dce4f6);
      text-align: left;
      font-weight: var(--mu-thead-font-weight);
    }
  }
}
