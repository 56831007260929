.tagTitle {
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
  justify-content: space-between;
  margin-right: 28px;

  .icon {
    font-size: 16px;
    margin-left: 10px;
  }
}

.tagList > * {
  width: 30%;
}
.tag {
  max-width: 30vw;
}
