.layoutHeader {
  // height: 46px;
  padding: 3px 16px;
  background-color: white;
  color: white;
  border-bottom: 1px solid #ebeff1;
  box-sizing: border-box;
  box-shadow: 0 -1px 2px 0 rgba(101, 117, 161, 0.13);

  .brand {
    display: inline-block;
    height: 32px;
    width: 166px;
  }

  .headerIcon {
    display: inline-block;
    padding: 10px;
    font-size: 22px;
    line-height: 1;
    color: #0969d2;
  }

  &.style_ghost {
    background-color: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    border-bottom: none;

    .headerIcon {
      color: white;
    }

    .brand {
      // background-image: url('assets/images/logo-blue.svg');
    }
  }
}
