.cell {
  .icon {
    display: none;
    margin: 0 30px;
  }
  .code {
    display: inline-block;
    margin-left: 20px;
  }
  &.selected {
    color: var(--mu-success-color);
    --rv-cell-value-color: currentColor;
    .icon {
      display: inline;
    }
  }
}
