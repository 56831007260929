.rangInputGroup {
  border-radius: 8px;
  border: 1px solid var(--mu-border-color);
  margin-bottom: 20px;
  overflow: hidden;

  .input {
    padding: 7px 16px;
    flex: 1;
    & + .input {
      border-left: 1px solid var(--mu-border-color);
    }
  }

  .rightItem {
  }
}
.sliderWrapper {
  height: 20px;
  margin: 35px 14px 10px;
}

.itemLabel {
  font-weight: bold;
  color: #10294d;
  margin: 16px 0;
}

.starOption {
  line-height: 26px;
  padding: 10px;
  background: #f6f7fb;
  border-radius: 8px;
  color: #8b91a1;
  text-align: center;
  &.active {
    background: var(--mu-primary-color);
    color: white;
  }
}

.footer {
  margin-bottom: 12px;

  .confirmBtn {
    width: 128px;
    // border-radius: 8px;
    // background-color: #466dec;
    box-shadow: 0px 2px 6px 0px #9fafe3;
  }
}
