.loginGuide {
  display: flex;
  align-items: center;
  padding: 4px 20px;
  border-radius: var(--mu-border-radius);
  background-color: #def2ff;
  gap: 10px;
  // margin-bottom: 18px;
  .img {
    width: 70px;
  }

  .content {
    font-size: 16px;
    font-weight: 500;
    flex: 1;
    color: var(--mu-title-color);
  }

  .btn {
  }
}
