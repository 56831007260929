.sideNav {
  background-color: #fff;
  position: relative;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e9eaee;

  padding: 20px;

  &:hover {
    border-color: var(--mu-reactiv-doc-active-color, #0757af);
  }

  .value {
    font-size: 16px;
    font-family: ArialMT;
    color: #4f4c4c;
    line-height: 18px;
  }
}

.dropdown {
  padding: 12px 18px;
  .item {
    padding: 6px 0;
    width: calc(100vw - 70px);
    max-width: 768px - 70px;

    &:hover {
      color: var(--mu-reactiv-doc-active-color, #0757af);
    }
  }
}
.popover {
  :global(.rv-popover__wrapper) {
    display: block;
  }
}

.cell {
  box-shadow: 0px 4px 10px 0px rgba(213, 213, 217, 0.5);
  border-radius: 4px;
  .title {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
  }

  .item {
    background-color: #fff;
    border-top: 1px solid #e9eaee;

    .heading {
      padding: 12px 20px;
      // font-weight: bold;
      cursor: pointer;
    }

    .contentWrapper {
      height: 0;
      transition: 0.5s height;
      overflow: hidden;

      .content {
        margin-left: 16px;
        line-height: 24px;
        padding: 0px 20px 12px;
      }
    }

    &.opened {
      background-color: #fcfbfd;
      .contentWrapper {
        display: unset;
      }
    }
  }
}

.toggle {
  padding: 0 10px;
  color: #48494a;
  cursor: pointer;
  position: relative;
  left: 10px;
  &:hover {
    color: var(--mu-link-color);
  }
  .icon {
    transition: 0.5s transform;
    // transform: rotateZ(90deg);
    font-size: 10px;
  }
}

.opened {
  .toggle {
    .icon {
      transform: rotateZ(-90deg);
    }
  }
}
