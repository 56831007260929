.searchPanel {
  --rv-cell-vertical-padding: 16px;
  .staydate {
    .seperater {
      // height: 24px;
      padding: 0 7px;
      border-radius: 12px;
      border: 1px solid var(--mu-border-color);
    }
  }
}
