.loginGuide {
  background-color: #def2ff;
  // height: 118px;
  .inner {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  // margin-bottom: 18px;
  .img {
    width: 50px;
  }

  .content {
    font-size: 14px;
    font-weight: 500;
    flex: 1;
    color: var(--mu-title-color);
    text-align: center;
  }

  .btn {
    margin-top: 12px;
    text-align: center;
  }
}
